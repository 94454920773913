<template>
  <CContainer class="d-flex min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="12" style="padding: 60px 80px 70px 80px">
        <div class="w-100">
          <div class="clearfix">
            <div class="font-weight-bold mt-5">
              <p class="text-title">{{ title }}</p>
            </div>
            <div>
              <div
                class="accordion justify-content-center"
                role="tablist"
                v-for="(item, index) in detailFixedPageByUser.description"
                :key="index"
              >
                <b-card
                  no-body
                  class="mb-0 text-center justify-content-center w-100"
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button
                      block
                      v-on:click="clickButton(index)"
                      class="d-flex justify-content-between custom-button align-items-center"
                      ><div class="d-flex flex-nowrap align-items-center">
                        <span class="common-title d-flex align-items-center"
                          >使い方<span>{{ index + 1 }}</span>
                        </span>
                        <span class="pl-3">{{ item.input }} </span>
                      </div>
                      <b-icon icon="chevron-compact-down"></b-icon
                    ></b-button>
                  </b-card-header>
                  <b-collapse
                    :id="'accordion' + index"
                    accordion="my-accordion"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <b-card-text v-html="item.description"></b-card-text>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "Page404Admin",
  data() {
    return {
      dataShop: [],
      homepage: window.location.origin,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      title: null,
      description: null,
      checked: null,
    };
  },
  created() {
    const dataRequest = {
      id: 1,
    };
    this.getFixedPageByIdScreenUser(dataRequest);
  },
  computed: {
    ...mapGetters(["detailFixedPageByUser"]),
  },
  watch: {
    detailFixedPageByUser() {
      this.title = this.detailFixedPageByUser.title;
    },
  },
  methods: {
    ...mapActions({ getFixedPageByIdScreenUser: "getFixedPageByIdScreenUser" }),
    clickButton(index) {
      this.$root.$emit("bv::toggle::collapse", "accordion" + index);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.5rem;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid;
  border-radius: 0;
  background-color: #fff;
  border-color: #fff;
}
.link-home-page:hover {
  color: #aaa !important;
}
.text-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 22px;
  line-height: 30px;
  margin-top: 0;
}
.card-header {
  background-color: #fff;
  border-color: #fff;
}
.common-title {
  background-color: #fff;
  border: 1px solid black;
  padding: 5px;
}
.custom-button {
  border: 0px;
  background-color: rgb(255, 255, 255);
  overflow-anchor: none;
  border-top: 1px solid #e6e6e6;
}
.custom-button:hover {
  background-color: #e6e6e6 !important;
}
.custom-button:focus {
  border: 0px !important;
  box-shadow: none !important;
}
</style>
